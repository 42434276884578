import { EventEmitter, Injectable } from '@angular/core';
import { User } from '../types/user.type';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private user: User = null;
  public userEventEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private router: Router,
  ) {
    this.reloadFromLocalStorage();
    this.reloadFromServer();
  }

  reloadFromLocalStorage() {
    this.user = JSON.parse(window.localStorage.getItem('user') || null);
  }

  reloadFromServer() {
    if (!this.isUserLoggedIn()) return;

    this.apiService.get('user/').subscribe({
      next: (user: User) => {
        user['token'] = this.user['token'];
        this.setUser(user);
      },
      error: (err) => {
        console.log("error:", err);
      },
    });
  }

  forgotPassword(email: string) {
    return this.apiService.post('forgot-password/', { 'email': email }, false, false);
  }

  resetPassword(password: string, token: string) {
    return this.apiService.post('reset-password-with-token/', { password, token }, false, false);
  }

  login(credentials: { email: string, password: string }) {
    return new Observable((observer) => {
      this.apiService.post('api-token-auth/', credentials, false, false).subscribe({
        next: (data: { token: string }) => {
          this.apiService.getUserAfterLogin('user/', data['token']).subscribe({
            next: (user: User) => {
              user['token'] = data['token'];
              this.setUser(user);
              observer.complete();
            },
            error: (err) => {
              console.log("error:", err);
              observer.error(err);
            },
          });
        },
        error: login_error => {
          console.log("error:", login_error);
          observer.error(login_error);
        }
      });
    });
  }

  logout() {
    window.localStorage.removeItem('user');

    this.user = null;
    this.userEventEmitter.emit(null);
  }

  setUser(user: User) {
    this.user = user;
    window.localStorage.setItem('user', JSON.stringify(user));
    this.userEventEmitter.emit(this.user);
  }

  getUser() {
    return this.user;
  }

  isUserLoggedIn() {
    return !!this.user;
  }

  redirectIfLoggedIn() {
    if (!this.isUserLoggedIn()) {
      window.localStorage.clear();
      return;
    }

    if (this.user.role === 'driver') {
      this.router.navigate(['/ritten']);
    } else {
      this.router.navigate(['/bestellingen']);
    }
  }

  isAccountManager() {
    return this.user?.role === 'admin' || (this.user?.role === 'backoffice' && this.user?.is_account_manager);
  }

  isProjectManager() {
    return this.user?.role === 'admin' || (this.user?.role === 'backoffice' && this.user?.is_project_manager);
  }

  isStockManager() {
    return this.user?.role === 'admin' || (this.user?.role === 'backoffice' && this.user?.is_stock_manager);
  }

  isDriverManager() {
    return this.user?.role === 'admin' || (this.user?.role === 'backoffice' && this.user?.is_driver_manager);
  }

  isBackoffice() {
    return this.user?.role === 'admin' || this.user?.role === 'backoffice';
  }

}
