import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from '../types/user.type';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  private BASE_URL = environment.apiUrl;
  private authenticationToken: string = null;
  private user: User = null;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  getUser() {
    const user = window.localStorage.getItem('user');
    return user ? JSON.parse(user) : undefined;
  }

  getHttpHeaders(isNotJSON = false, authRequire = true) {
    const headers: any = (isNotJSON) ? {} : {'Content-Type': 'application/json'};

    this.user = this.getUser();
    if (this.user) {
      headers['Authorization'] = 'Bearer ' + this.user['token'];
    } else if (authRequire) {
      this.router.navigateByUrl('login');
    }
    return new HttpHeaders(headers);
  }

  getUserAfterLogin(url: string, token: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    });
    return this.http.get(this.BASE_URL + url, { 'headers': httpHeaders });
  }

  get(url: string, query: any = {}, bypassServiceWorker = true, authRequire = true): Observable<any> {
    const httpHeaders = this.getHttpHeaders(false, authRequire);
    const httpParams = new HttpParams({ fromObject: query });
    return this.http.get(this.BASE_URL + url, { 'headers': httpHeaders, 'params': httpParams });
  }

  post(url: string, body: any, isNotJSON = false, authRequire = true): Observable<any> {
    const httpOptions = this.getHttpHeaders(isNotJSON, authRequire);
    return this.http.post(this.BASE_URL + url, body, { 'headers': httpOptions });
  }

  put(url: string, body: any, isNotJSON = false): Observable<any> {
    const httpOptions = this.getHttpHeaders(isNotJSON);
    return this.http.put(this.BASE_URL + url, body, { 'headers': httpOptions });
  }

  patch(url: string, body: any): Observable<any> {
    const httpOptions = this.getHttpHeaders();
    return this.http.patch(this.BASE_URL + url, body, { 'headers': httpOptions });
  }

  delete(url: string): Observable<any> {
    const httpOptions = this.getHttpHeaders();
    return this.http.delete(this.BASE_URL + url, { 'headers': httpOptions });
  }

}
