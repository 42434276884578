import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription, concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService {

  // private intervalSubscription: Subscription;

  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
  ) { }

  checkForUpdates(): void {
    // this.intervalSubscription?.unsubscribe();
    if (!this.swUpdate.isEnabled) {
      return;
    }

    // // Encountering an issue with the app doesn't become stable, supposedly because of a pending async operation.
    // console.log('Checking for updates when app is stable...');
    // const appIsStable$ = this.appRef.isStable.pipe(first(isStable => {
    //   console.log('App is stable:', isStable);
    //   return isStable === true;
    // }));
    // // const interval$ = interval(30 * 1000); // every 30 sec
    // // const everyIntervalOnceAppIsStable$ = concat(appIsStable$, interval$);

    // this.intervalSubscription = appIsStable$.subscribe(() => {
    //   console.log('checking for updates');
    //   this.swUpdate.checkForUpdate().then((hasUpdate) => {
    //     console.log(hasUpdate ? 'A new version is available.' : 'Already on the latest version.');
    //   }).catch(error => {
    //     console.error('Failed to check for updates:', error);
    //   });
    // });


    interval(30000).subscribe(() => {
      // Ideally, you should check for updates once the app is stable.
      this.swUpdate.checkForUpdate().then(() => {
        console.log('checking for updates');
      });
    });

  }

}
