/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


import { registerLocaleData } from '@angular/common';
import localeBe from '@angular/common/locales/nl-BE';
import localeBeExtra from '@angular/common/locales/extra/nl-BE';
registerLocaleData(localeBe, "nl-BE", localeBeExtra);
