import { Component, OnInit } from '@angular/core';
import { CheckForUpdateService } from './services/pwa/check-for-update.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private checkForUpdateService: CheckForUpdateService,
  ) { }

  ngOnInit() {
    this.checkForUpdateService.checkForUpdates();
    this.loadGoogleMapsAPI();
  }

  loadGoogleMapsAPI() {
    const url_base = "https://maps.googleapis.com/maps/api/js";
    const params = {
      key: environment.googleMapsApiKey,
      libraries: 'places',
      loading: 'async',
    }
    const url = url_base + '?' + new URLSearchParams(params);
    
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
    console.log('Script Loaded');
  }

}
